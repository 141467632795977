import { Fragment, useEffect, useState } from "react";
import './style.css';

const initialDoors = ['goat', 'goat', 'goat'];

const MontyHall = () => {
  const [doors, setDoors] = useState([]);
  const [selectedDoorIndex, setSelectedDoorIndex] = useState(-1);
  const [secondSelectedDoorIndex, setSecondSelectedDoorIndex] = useState(-1);
  const [hintDoorIndex, setHintDoorIndex] = useState(-1);
  const [isSelectingDoor, setIsSelectingDoor] = useState(true);
  const [isSwitchingDoor, setIsSwitchingDoor] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [switchStats, setSwitchStats] = useState([]);
  const [stayStats, setStayStats] = useState([]);
  const [statsSummary, setStatsSummary] = useState({ switchedWins: 0, switchedTotal: 0, stayedWins: 0, stayedTotal: 0 });

  const init = () => {
    const carIndex = Math.floor(Math.random() * initialDoors.length);
    setDoors(initialDoors.map((door, i) => i === carIndex ? 'car' : door));
    setSelectedDoorIndex(-1);
    setSecondSelectedDoorIndex(-1);
    setHintDoorIndex(-1);
    setIsSelectingDoor(true);
    setIsSwitchingDoor(false);
    setIsGameOver(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const switchedWins = switchStats.filter(s => s.isWin).length;
    const switchedTotal = switchStats.length;
    const stayedWins = stayStats.filter(s => s.isWin).length;
    const stayedTotal = stayStats.length;
    setStatsSummary({ switchedWins, switchedTotal, stayedWins, stayedTotal });
  }, [switchStats, stayStats]);

  const handleDoorClicked = i => {
    if (isSelectingDoor && selectedDoorIndex === i) {
      setSelectedDoorIndex(-1);
      setSecondSelectedDoorIndex(-1);
    } else if (isSelectingDoor && selectedDoorIndex !== i) {
      setSelectedDoorIndex(i);
      setSecondSelectedDoorIndex(i);
    } else if (isSwitchingDoor && i !== hintDoorIndex) {
      setSecondSelectedDoorIndex(i);
    }
  }

  const handleNextClicked = () => {
    if (isSelectingDoor && selectedDoorIndex !== -1) {
      setIsSelectingDoor(false);
      setIsSwitchingDoor(true);
      const doorIndices = doors.map((_door, i) => i);
      const indices = doorIndices.filter(i => i !== selectedDoorIndex && doors[i] !== 'car');
      const _hintDoorIndex = indices[Math.floor(Math.random() * indices.length)];
      setHintDoorIndex(_hintDoorIndex);
    } else if (isSwitchingDoor) {
      setIsSwitchingDoor(false);
      setIsGameOver(true);
    } else if (isGameOver) {
      if (selectedDoorIndex === secondSelectedDoorIndex) {
        setStayStats(prev => [{ isWin: doors[secondSelectedDoorIndex] === 'car', gameNumber: prev.length + 1 + switchStats.length }, ...prev]);
      } else {
        setSwitchStats(prev => [{ isWin: doors[secondSelectedDoorIndex] === 'car', gameNumber: prev.length + 1 + stayStats.length }, ...prev]);
      }
      init();
    }
  }
  
  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
    <h2 style={{ margin: 0, height: 100, padding: '0 4px', textAlign: 'center' }}>
      {isSelectingDoor ? <>{'Behind one of these doors is a new car!'}<br/>{'Select a door...'}</> : isSwitchingDoor ? <>{`Hint: Behind door ${hintDoorIndex + 1} is a goat.`}<br/>{`Select a new door or keep door ${selectedDoorIndex + 1}...`}</> : `You ${selectedDoorIndex === secondSelectedDoorIndex ? 'kept' : 'switched'} your door and ${doors[secondSelectedDoorIndex] === 'car' ? 'won 🚗' : 'lost 🐐'}`}
    </h2>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {doors.map((door, i) => <button
        key={i}
        className='door'
        style={{
          fontSize: 20,
          border: `3px solid ${(isSelectingDoor && i === selectedDoorIndex) || i === secondSelectedDoorIndex ? '#008000ba' : 'rgba(184, 32, 32, 0.677)'}`,
          backgroundColor: isGameOver || i === hintDoorIndex ? 'transparent' : (isSelectingDoor && i === selectedDoorIndex) || i === secondSelectedDoorIndex ? '#008000ba' : 'rgba(184, 32, 32, 0.677)'
        }}
        onClick={() => handleDoorClicked(i)}
      >
        {(isGameOver || (hintDoorIndex === i)) ? door === 'car' ? '🚗' : '🐐' : i + 1}
      </button>)}
    </div>
    <button
      className='main-btn'
      onClick={handleNextClicked}
      disabled={isSelectingDoor && selectedDoorIndex === -1}
      style={{ width: 200 }}
    >
      {isSelectingDoor ? selectedDoorIndex === -1 ? 'Select a door' : `Select door ${selectedDoorIndex + 1}` : isSwitchingDoor ? selectedDoorIndex === secondSelectedDoorIndex ? `Keep door ${selectedDoorIndex + 1}` : `Switch door ${selectedDoorIndex + 1} for door ${secondSelectedDoorIndex + 1}` : 'Play Again'}
    </button>
    <div style={{ flex: 1, overflow: 'hidden', width: 250, maxHeight: 400, padding: 10 }}>
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: '#7f7f7f17', borderRadius: 8, color: '#a7a7a7' }}>
        <div style={{ height: 70, borderBottom: '1px solid #00000014', margin: '2px 8px' }}>
          {!!(statsSummary.switchedTotal || statsSummary.stayedTotal) && <Fragment>
            <h3 style={{ margin: 0, textAlign: 'center' }}>Win rates</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Switched Doors:<br/>{statsSummary.switchedWins}/{statsSummary.switchedTotal} ({(statsSummary.switchedWins / (statsSummary.switchedTotal || 1) * 100).toFixed(2)}%)</div>
              <div>Kept Door:<br/>{statsSummary.stayedWins}/{statsSummary.stayedTotal} ({(statsSummary.stayedWins / (statsSummary.stayedTotal || 1) * 100).toFixed(2)}%)</div>
            </div>
          </Fragment>}
        </div>
        <div style={{ padding: '4px', height: 'calc(100% - 70px)', overflowY: 'auto', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {switchStats.map((stat, i) => <div
              key={i}
            >
              {`Game ${stat.gameNumber}: ${stat.isWin ? 'W 🚗' : 'L 🐐'}`}
            </div>)}
          </div>
          <div>
            {stayStats.map((stat, i) => <div
              key={i}
            >
              {`Game ${stat.gameNumber}: ${stat.isWin ? 'W 🚗' : 'L 🐐'}`}
            </div>)}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default MontyHall;