import StarsScreenSaver from 'react-stars-screensaver';
import './style.css';

const Starscape = () => {
  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'safe center', justifyContent: 'center', height: '100%' }}>
    <div className='starscape-container'>
      <p>
        I deployed my own public NPM package: React Stars Screensaver!<br/>
        Click <a
          target='_blank'
          rel='noreferrer'
          href={'https://www.npmjs.com/package/react-stars-screensaver'}
        >
          here
        </a> for more info about the package and instructions on how use it in your own React project.
      </p>
      <StarsScreenSaver
        height={500}
        width={800}
      />
    </div>
  </div>
}

export default Starscape;