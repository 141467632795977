import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NavBar = props => {
  const {
    setWrapperClass
  } = props;

  const [activePath, setActivePath] = useState(window.location.pathname);

  const navigate = useNavigate();

  useEffect(() => {
    setActivePath(window.location.pathname);
  }, [navigate]);

  const handleNav = async e => {
    e.preventDefault();
    setWrapperClass('fade-out-fade-in');
    setTimeout(() => {
      const path = e.target.href.replace(window.location.origin, '');
      navigate(path);
    }, 200);
    setTimeout(() => {
      setWrapperClass('');
    }, 400);
  }

  return <div className='App-header'>
    <div className='App-nav'>
      <a
        href='/'
        className={activePath === '/' && 'active'}
        onClick={handleNav}
      >
        Home
      </a>
      <a
        href='/starscape'
        className={activePath === '/starscape' && 'active'}
        onClick={handleNav}
      >
        Starscape
      </a>
      <a
        href='/snake'
        className={activePath === '/snake' && 'active'}
        onClick={handleNav}
      >
        Snake
      </a>
      <a
        href='/monty-hall'
        className={activePath === '/monty-hall' && 'active'}
        onClick={handleNav}
      >
        Monty Hall Sim
      </a>
    </div>
  </div>
}

export default NavBar;