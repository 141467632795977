import { useState } from 'react';
import {
  Routes,
  Route,
  BrowserRouter
} from 'react-router-dom';
import Home from './Pages/Home';
import Starscape from './Pages/Starscape';
import Snake from './Pages/Snake';
import MontyHall from './Pages/MontyHall';
import NavBar from './NavBar';
import NotFound from './Pages/NotFound';
import './App.css';

const App = () => {
  const [wrapperClass, setWrapperClass] = useState('');

  return <div className='App'>
    <BrowserRouter basename='/'>
      <NavBar setWrapperClass={setWrapperClass}/>
      <div className={`App-body${wrapperClass ? ` ${wrapperClass}` : ''}`}>
        <Routes>
          <Route path='*' element={<NotFound/>}/>
          <Route path='/' element={<Home/>}/>
          <Route path='/starscape' element={<Starscape/>}/>
          <Route path='/snake' element={<Snake/>}/>
          <Route path='/monty-hall' element={<MontyHall/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  </div>
}

export default App;